<template>    
    <b-modal
		size="md"
		id="acept-social-promos"
		title="Aceptar"
		ok-only
		centered
		hide-footer
		no-close-on-backdrop
		hide-header
        modal-bg-variant="custom-opacity"
	>

        <b-overlay :show="isAcceptingSharePromotions">
            <div class="modal-container">

                <div class="content-accept mt-2" v-if="langLocale === 'en' " >

                    <p class="p-intro">
                        
                        <span>The Fives Vacations</span>  is <span>The Fives Club</span>  and <span>Epic Travelers</span>  signature program designed for referrals who wish to book at <span> The Fives Hotels & Residences</span>. 
                    </p>

                    <p class="p-main">
                        Here you can share your promotion codes on <a href="https://www.facebook.com/" target="_blank">Facebook</a>, <a href="https://twitter.com/" target="_blank">Twitter</a>, <a href="https://www.linkedin.com/" target="_blank">Linkedin</a> or other social networks so that your family, friends or followers book at our hotels with a preferred rate. This way you can earn additional income in Cash or Resort Credits as a promoter of our brand. 
                    </p>

                    <p>
                        The use of this service has an annual cost of $200.00 USD + taxes. 
                    </p>
                </div>

                <div class="content-accept mt-2" v-else>

                    <p class="p-intro">
                        <span>The Fives Vacations</span> es el programa insignia de <span>The Fives Club</span> y <span>Epic Travelers</span> para referidos que deseen reservar en <span> The Fives Hotels & Residences</span>.
                    </p>

                    <p class="p-main">
                        Aquí podrás compartir tus códigos de promoción en <a href="https://www.facebook.com/" target="_blank">Facebook</a>, <a href="https://twitter.com/" target="_blank">Twitter</a>, <a href="https://www.linkedin.com/" target="_blank">Linkedin</a> u otras redes sociales para que tus familiares, amigos o seguidores reserven en nuestros hoteles con una tarifa preferencial, obteniendo así un ingreso adicional en Cash o Resort Credits como promotor de nuestra marca. 
                    </p>

                    <p>
                        El uso de este servicio tiene un costo anual de $200.00 USD + impuestos. 
                    </p>
                </div>


                <div class="container-buttons mt-3 pt-2">
                    <button class="btn-accepts"  @click="setShareSocialPromos"> {{ $t('I want to use this service') }}</button>
                    <button class="btn-accepts"  @click="closeModal">{{ $t('I am not interested')  }}</button>
                </div>

            </div>
        </b-overlay>

	</b-modal>
</template>

<script>

import { mapActions, mapState, mapGetters  } from 'vuex'

import { utils } from '@/modules/owners/mixins/utils'

export default {
    
	mixins: [ utils ],

    props:{
        isAcceptingSharePromotions: {
            Type: Boolean,
            required: true,
        }
    },
	
	computed: {
		...mapState("auth", ["user"]),	
		...mapGetters('auth',['contractSelected']),
        langLocale(){
            return this.$i18n.locale
        }
	},

	methods:{
		
		...mapActions('owners', ['getPromosOwner']),
		...mapActions('owners', ['acceptPromosOwner']),

        async closeModal(){
			this.$root.$emit('bv::hide::modal', 'acept-social-promos')
			await this.pushData('onClick', 'declineToSharePromotions', 'declineToSharePromotions', this.$route.name) // push a back del registro		
        },

        setShareSocialPromos(){

            // validar si es admin desde mixin en utils
			if( this.isAdmin ){
			    this.$root.$emit('bv::hide::modal', 'acept-social-promos')
				return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
			}

            this.$emit('set-share-social-promos')
        }
		
	},
 
}
</script>
<style>
/* Estilo personalizado para quitar el border-radius del b-modal */

#acept-social-promos .modal-content {
    height: 30rem;
    border: 0.5px solid #6b6a6a;
    border-radius: 0!important;
    /* box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1); */
}

.container-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-accepts {
  background-color: #333; /* Gris oscuro */
  color: #fff; /* Texto blanco */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
}

/* Estilos adicionales para pantallas más grandes */
@media (min-width: 768px) {
  .container-buttons {
    flex-direction: row;
    justify-content: space-around;
  }

  .btn-accepts {
    margin: 5px;
  }
}

.content-accept {
    text-align: justify;
    color: black;
}

.p-intro span {
   font-style: italic; 
   font-weight: bold;
}

.p-main a{
    font-weight: bold;
}




/*
{ opacity: .3; background-color: rgb(0,0,0,.3); height: 100%; } modal <--cuadro gris (border) element { position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); display: block; background: #999; width: 600px; height: 300px; padding: 5px; } inner modal <--cuadro blanco element { background: #fff; display: block; width: 100%; height: 100%; padding: 10px; text-align: justify; } clase para los nombres de marcas .hlgt { font-style: italic; font-weight: bold; } */

</style>
