<template>
    <div class="social-card">
        <div class="social-card-image">
            <img  class="foregroundImg" :src="imagePromo" alt="Social-card Image" @error="imgAlternativo">
            <!-- <img  class="backgroundImg" :src="imagePromo" alt="Social-card Image" @error="imgAlternativo"> -->
        </div>
        <div class="share-content">
            <h3 class="share-title">{{ promo.title }} </h3>
            <p class="share-description">{{ promo.description }}</p>
			<p> {{ $t('duration') }} : {{ discountDuration }}</p>

            <div class="buttonSocial-container">
                
                <button
					class="buttonSocial btn-facebook"
					v-b-tooltip.hover
					:title="$t('Share on facebook')"
					@click="shareToSocialNetwork('facebook')"
					v-if="promo.socialNetworks.includes('facebook')"
				>
                    <FacebookIcon/>
                </button>

				<button
					class="buttonSocial btn-instagram" 
					v-b-tooltip.hover
					:title="$t('Share on Instagram')" 
					@click="shareToSocialNetwork('instagram')"
					v-if="false"
				>
                    <InstagramIcon/>
                </button>

                <!-- <a class="button btn-whatsapp">
                    <WhatsAppIcon/>
                </a> -->
                <button
					class="buttonSocial btn-linkedin" 
					v-b-tooltip.hover
					:title="$t('Share on LinkedIn')"
					@click="shareToSocialNetwork('linkedin')"
					v-if="promo.socialNetworks.includes('linkedin')"
				>
                    <LinkedInIcon/>
                </button>
                <button
					class="buttonSocial btn-twitter"
					v-b-tooltip.hover
					:title="$t('Share on Twitter')"
					@click="shareToSocialNetwork('twitter')"
					v-if="promo.socialNetworks.includes('twitter')"
				>
                    <TwitterIcon/>
                </button>
				
                <button class="buttonSocial btn-link"  v-b-tooltip.hover :title="$t('Share link')" @click="shareOnlyLink">
                    <LinkIcon/>
                </button>
            </div>
        </div>
	</div>    
</template>  
  

<script>
import {  mapState, mapGetters } from 'vuex'

import InstagramIcon from '@/modules/owners/components/SocialPromos/icons/InstagramIcon'
import FacebookIcon from '@/modules/owners/components/SocialPromos/icons/FacebookIcon'
import WhatsAppIcon from '@/modules/owners/components/SocialPromos/icons/WhatsAppIcon'
import LinkedInIcon from '@/modules/owners/components/SocialPromos/icons/LinkedInIcon'
import TwitterIcon from '@/modules/owners/components/SocialPromos/icons/TwitterIcon'
import LinkIcon from '@/modules/owners/components/SocialPromos/icons/LinkIcon'
import { showAlertMessage } from "@/helpers/helpers"
import { utils } from "@/modules/owners/mixins/utils"

export default {
	mixins: [ utils ],
	props:{
		promo:{
			type: Object,
			required: true,
		}
	},
    components: {
        InstagramIcon, 
		FacebookIcon,  
		WhatsAppIcon, 
		LinkedInIcon,
		TwitterIcon,
		LinkIcon,
    },
	data(){
		return {
			popupTop: 0,
      		popupLeft: 0,
			popup:{
				width: 626,
        		height: 436
			},
			imdDefault: require('@/assets/images/default.jpg'),
		}
	},
	computed: {
		...mapState("auth", ["user"]),	
		...mapGetters('auth',['contractSelected']),
			
		discountDuration(){
			let dateInit = ''
			let dateEnd = ''
			let to = ''

			const options = {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
			}

			if ( this.$i18n.locale == 'en' ) {
				
				dateInit =  new Date(this.promo.startDate).toLocaleDateString('en-US', options)
				dateEnd =  new Date(this.promo.endDate).toLocaleDateString('en-US', options)
				to = ' to '
			} 

			if ( this.$i18n.locale == 'es' ) {
				dateInit =  new Date(this.promo.startDate).toLocaleDateString('es-ES', options);
				dateEnd =   new Date(this.promo.endDate).toLocaleDateString('es-ES', options);
				to = ' a '
			} 

			return `${dateInit} ${to} ${dateEnd}`
		},
		imagePromo(){
			return process.env.VUE_APP_IMG_SRC_API + this.promo.imagePromo
		}
	},
    methods:{
		async shareToSocialNetwork( socialNetwork ){

			const socialNetworks = {
				facebook: 'sharePromoFacebook',
				linkedin: 'sharePromoLinkedIn',  	  
				twitter: 'sharePromoTwitter',
			}

			// const configPopup = this.configPopup() // popup centering
			let urlShare = null

			if( socialNetwork === 'facebook' ){
				urlShare = this.urlFacebook()
			}

			if( socialNetwork === 'linkedin' ){
				urlShare = this.urlLinkedIn()
			}

			if( socialNetwork === 'twitter' ){
				urlShare = this.shareOnTwitter()
			}

			// validar si es admin desde mixin en utils
			if( this.isAdmin ){
				return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
			}

			await this.pushData('onClick', socialNetworks[socialNetwork], socialNetworks[socialNetwork], this.$route.name) // push a back del registro		

			return window.open(urlShare, '_blank') 
	   },
	   urlFacebook(){
			const promotion = JSON.parse( JSON.stringify( this.promo ) ) 
			const { description, title } = promotion
			const urlBase = 'https://www.facebook.com/sharer/sharer.php'
			return `${urlBase}?u=${this.publicationUrl()}&quote=${description}&title=${title}&picture=${this.imagePromo}`
	   },
	  
	   urlLinkedIn(){
			const promotion = JSON.parse( JSON.stringify( this.promo ) ) 
			const { description, title } = promotion
			const urlBase = 'https://www.linkedin.com/shareArticle'
		 	return `${urlBase}?mini=true&url=${this.publicationUrl()}&title=${title}&source=${this.imagePromo}&summary=${description}`
	   },
	   shareOnInstagram(){
			const promotion = JSON.parse( JSON.stringify( this.promo ) ) 
			const { description, title, codePromo } = promotion
			const urlBase = 'instagram://library'
			return `${urlBase}?AssetPath=${this.imagePromo}&Caption=${title}, ${description} code:${codePromo}`
	   },
	   shareOnTwitter(){
			const promotion = JSON.parse( JSON.stringify( this.promo ) ) 
			const {  title, codePromo } = promotion
			const urlBase = 'https://twitter.com/intent/tweet'
			// URL y texto que deseas compartir
			var url = encodeURIComponent(this.publicationUrl()) // URL de tu página
			var text = encodeURIComponent(`${title} ${ this.$t('with my discount code') } : ${codePromo} ${this.numberHouse()}`); // Texto que deseas compartir
			
			// Combinar URL y texto en el enlace de compartir
			return  `${urlBase}?url=${url}&text=${text}`
	   },
		async shareOnlyLink(){
			
			// validar si es admin desde mixin en utils
			if( this.isAdmin ){
				return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
			}

			const title = this.$t('Link copied') //enlace copiado
			const msg = `${ this.$t('Your link has been copied for sharing') } : ${ this.publicationUrl() }` // Su enlace ha sido copiado para poder compartir
			await this.pushData('onClick', 'sharePromoLink', 'sharePromoLink', this.$route.name) // push a back del registro		

			try {
				const elementoTemp = document.createElement('textarea')
				elementoTemp.value = this.publicationUrl()
				document.body.appendChild(elementoTemp)
				elementoTemp.select();
				document.execCommand('copy')
				document.body.removeChild(elementoTemp)
				showAlertMessage( title , "LinkIcon", msg, "success", 3000, "bottom-right")
			} catch (error) {
				//Error al copiar al portapapeles
				showAlertMessage( this.$t('Error while copying') , "InfoIcon", this.$t('Error when copying to clipboard'), "danger", 3000, "bottom-right")
			}
			
	   },
	   publicationUrl(){
			const promotion = JSON.parse( JSON.stringify( this.promo ) ) 
			const { codePromo } = promotion
			const lang = this.$i18n.locale == 'es' ? this.$i18n.locale: ''
			return `https://www.thefivesvacations.com/${lang}?cp=${codePromo}${this.numberHouse()}` // armo la url de la promoción
		},
		numberHouse(){
			const numberH = this.contractSelected ? this.contractSelected.housingnumber : ''
			
			if( numberH.toString().length >= 5 ){
				return numberH
			}
			
			const zerosToAdd = 5 - numberH.toString().length
			
			return  `${'0'.repeat(zerosToAdd)}${numberH}`
			
		},
		isCupertinoDevice(){
			// Verificar si el dispositivo es un iPhone o iPad
			const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

			// Verificar si el dispositivo es un Mac (macOS)
			const isMac = /Macintosh/.test(navigator.userAgent)

			// Verificar si el dispositivo es un iPad
			const isIPad = /iPad/.test(navigator.userAgent)

			return isIOS || isMac || isIPad
		},
		imgAlternativo( event ){
			event.target.src = this.imdDefault
		},


    }

}
</script>

<style scoped>
.social-card {
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	/* padding: 20px; */
	max-width: 400px;
	margin: 0 auto;
	min-width: 320px;
	width: 360px;
}

.social-card-image {
	/* margin-bottom: 50px; */
    max-width: 100%; 
    height: 250px;
}

.social-card-image img {
	display: block; /* Para asegurar que la imagen se comporte como bloque */
    border-radius: 8px 8px 0 0;
    max-width: 100%; /* La imagen no excederá el ancho del div */
    height: auto; /* Ajusta la altura proporcionalmente */
    object-fit: cover;
    width: 100%; /* Asegura que la imagen llene todo el ancho disponible */
}


.share-content {
	/* background-color: #f0f0f0; */
	/* border-radius: 5px; */
	padding: 1rem 1.5rem 2rem 1.5rem ;
	/* text-align: center; */	
	margin: 0 auto;	
}

.share-title {
	font-size:1rem;
	font-weight: bold;
	margin-bottom: 10px;
	height: 35px; /* Espacio fijo al titulo */

}

.share-description {
  color: #777;
  margin-bottom: 20px;
/* limito a solo dos lineas de la descripción */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  height: 80px;
}

.buttonSocial-container {
	display: flex;
	justify-content: space-between;
	max-width: 400px;
	margin: 0 auto;
}

.buttonSocial {
	position: relative;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background-color: #007bff;
	border-radius: 50%;
	color: #fff;
	font-size: 20px;
	margin-right: 10px;
	text-decoration: none;
	transition: background-color 0.3s ease;
}
.buttonSocial svg{
	position: absolute;
}

.btn-instagram{			
	background: linear-gradient(45deg, #833AB4, #C13584);
}

.btn-facebook{
	background-color: #1877F2;
}

.btn-whatsapp{
	background-color: #4dc247;
}

.btn-linkedin{
	background-color: #0073b1;
}

.btn-twitter{
	background-color: #55acee;
}

.btn-link{
	/* background: linear-gradient(45deg, #833AB4, #C13584);	
	background: linear-gradient(180deg, #FFFFFF 17.19%, rgba(255, 255, 255, 0) 100%); */
	background-color: #EBECFE;	
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.buttonSocial:last-child {
	margin-right: 0;
}

@media (max-width: 480px) {
	.buttonSocial-container {
	flex-wrap: wrap;
	}

	.buttonSocial {
	margin-bottom: 10px;
	}
}


</style>