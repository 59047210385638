<template>
	<svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_2780_36565)">
			<path d="M0.0571289 24L1.74413 17.837C0.703129 16.033 0.156129 13.988 0.157129 11.891C0.160129 5.335 5.49513 0 12.0501 0C15.2311 0.001 18.2171 1.24 20.4631 3.488C22.7081 5.736 23.9441 8.724 23.9431 11.902C23.9401 18.459 18.6051 23.794 12.0501 23.794C10.0601 23.793 8.09913 23.294 6.36213 22.346L0.0571289 24ZM6.65413 20.193C8.33013 21.188 9.93013 21.784 12.0461 21.785C17.4941 21.785 21.9321 17.351 21.9351 11.9C21.9371 6.438 17.5201 2.01 12.0541 2.008C6.60213 2.008 2.16713 6.442 2.16513 11.892C2.16413 14.117 2.81613 15.783 3.91113 17.526L2.91213 21.174L6.65413 20.193ZM18.0411 14.729C17.9671 14.605 17.7691 14.531 17.4711 14.382C17.1741 14.233 15.7131 13.514 15.4401 13.415C15.1681 13.316 14.9701 13.266 14.7711 13.564C14.5731 13.861 14.0031 14.531 13.8301 14.729C13.6571 14.927 13.4831 14.952 13.1861 14.803C12.8891 14.654 11.9311 14.341 10.7961 13.328C9.91313 12.54 9.31613 11.567 9.14313 11.269C8.97013 10.972 9.12513 10.811 9.27313 10.663C9.40713 10.53 9.57013 10.316 9.71913 10.142C9.87013 9.97 9.91913 9.846 10.0191 9.647C10.1181 9.449 10.0691 9.275 9.99413 9.126C9.91913 8.978 9.32513 7.515 9.07813 6.92C8.83613 6.341 8.59113 6.419 8.40913 6.41L7.83913 6.4C7.64113 6.4 7.31913 6.474 7.04713 6.772C6.77513 7.07 6.00713 7.788 6.00713 9.251C6.00713 10.714 7.07213 12.127 7.22013 12.325C7.36913 12.523 9.31513 15.525 12.2961 16.812C13.0051 17.118 13.5591 17.301 13.9901 17.438C14.7021 17.664 15.3501 17.632 15.8621 17.556C16.4331 17.471 17.6201 16.837 17.8681 16.143C18.1161 15.448 18.1161 14.853 18.0411 14.729Z" fill="#ffffff"/>
		</g>
		<defs>
			<clipPath id="clip0_2780_36565">
				<rect width="24" height="24" fill="white"/>
			</clipPath>
		</defs>
	</svg>    
</template>

<script>
export default {
  name: "WhatsAppIcon"
}
</script>